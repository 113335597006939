import { Dialog, DialogContent, DialogProps, DialogTitle, List } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchUserPageClaimsRequest, IUserDto, PageClaim, usersCommand_patchPageClaims } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useContext, useMemo, useState } from 'react';
import { RecordContext } from 'shared/records/RecordContext';
import * as yup from 'yup';
import { IPageClaimTree } from './IPageClaimTree';
import { TreePageClaimListItem } from './TreePageClaimListItem';
import { createPageClaimOrGroupRecord } from './utils/createPageClaimOrGroupRecord';

const createSchema = (strings: IStrings) => {
	return yup
		.object<IPatchUserPageClaimsRequest>({
			userId: yup.string().required(),
			pageClaims: yup.mixed(),
		})
		.defined();
};

const tree: IPageClaimTree[] = [
	{ claim: 'Dashboard' },
	{ claim: 'Calendar' },
	{
		claim: 'Patients',
	},
	{
		claim: 'processes',
		children: [{ claim: 'Orders' }, { claim: 'Repairs' }, { claim: 'Rfias' }, { claim: 'TailorMadeProducts' }, { claim: 'Loans' }],
	},
	{
		claim: 'inventory',
		children: [
			{ claim: 'ProductCatalog' },
			{ claim: 'InventoryItems' },
			{ claim: 'Stock' },
			{ claim: 'StockEntries' },
			{
				claim: 'stock-transactions',
				children: [{ claim: 'Deliveries' }, { claim: 'Returns' }, { claim: 'StockMovements' }],
			},
			{ claim: 'NoahDevices' },
			{ claim: 'SearchOnSerialNumbers' },
		],
	},
	{
		claim: 'financials',
		children: [
			{ claim: 'Tenders' },
			{ claim: 'Sales' },
			{ claim: 'Purchases' },
			{ claim: 'CustomerAccounts' },
			{ claim: 'SettlementProposals' },
			{ claim: 'SupplierAccounts' },
			{ claim: 'Payments' },
			{
				claim: 'commissions',
				children: [{ claim: 'MyCommissionPayouts' }, { claim: 'AllCommissionPayouts' }, { claim: 'MyCommissions' }, { claim: 'AllCommissions' }],
			},
			{ claim: 'DailyReceipts' },
			{ claim: 'CashRegisterEntries' },
		],
	},
	{ claim: 'CashRegister' },
	{
		claim: 'contacts',
		children: [{ claim: 'EntPhysicians' }, { claim: 'GeneralPractitioners' }, { claim: 'Suppliers' }, { claim: 'VatCustomers' }, { claim: 'Hifs' }],
	},
	{
		claim: 'settings',
		children: [{ claim: 'Templates' }, { claim: 'Locations' }, { claim: 'Users' }, { claim: 'BusinessData' }, { claim: 'PaymentMethods' }],
	},
	{
		claim: 'integrations',
		children: [
			{ claim: 'RosaIntegration' },
			{ claim: 'ExactOnlineIntegration' },
			{ claim: 'ExactGlobeIntegration' },
			{ claim: 'InvoiceForwardingIntegration' },
		],
	},
];

interface IProps extends DialogProps {
	user: IUserDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const UserPageClaimsForm = ({ user, confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [change, isSubmitting] = useFormSubmit(usersCommand_patchPageClaims);
	const strings = useLocalization();
	const [selected, setSelected] = useState<PageClaim[]>(user.pageClaims as PageClaim[]);
	const { pageClaimRecord } = useContext(RecordContext);
	const record = useMemo(() => createPageClaimOrGroupRecord(pageClaimRecord, strings), [pageClaimRecord, strings]);

	const handleSubmit = async (values: IPatchUserPageClaimsRequest, helpers: FormikHelpers<IPatchUserPageClaimsRequest>) => {
		const r = await change({ ...values, pageClaims: selected });
		if (handleValidateResponse(r, helpers, 'pageClaims')) {
			notify(strings.changedWhat(strings.accessToPages));
			confirm();
		}
	};

	return (
		<Formik<IPatchUserPageClaimsRequest>
			validateOnMount
			initialValues={{
				userId: user.id,
				pageClaims: [],
			}}
			validationSchema={createSchema(strings)}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					maxWidth='sm'
					{...rest}>
					<DialogTitle>{strings.accessToPages}</DialogTitle>
					<DialogContent
						className='df-col'
						dividers>
						<List
							dense
							style={{ marginLeft: '-24px', marginRight: '-24px' }}>
							{tree.map(t => (
								<TreePageClaimListItem
									key={t.claim}
									t={t}
									selected={selected}
									setSelected={setSelected}
									record={record}
								/>
							))}
						</List>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={strings.change}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
